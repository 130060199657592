<template>
  <div :class="classes">
    <span v-if="label" class="label">
      {{ label }}
      <span v-if="isRequired" class="required">*</span>
    </span>

    <div class="field-content">
      <input
        ref="input"
        class="field"
        :type="type"
        :name="name"
        :value="value"
        :readonly="isDisabled"
        :required="isRequired"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @keyup.enter="emit('submit', $event)"
      />

      <button-base v-if="btnAction" :type="btnType" :size="btnSize" :is-processing="isLoading" @click="clickActionBtn">
        {{ btnText }}
      </button-base>
    </div>

    <atomic-hint v-if="hint" v-bind="hint" />
  </div>
</template>

<script setup lang="ts">
  import type { IFieldHint } from '@skeleton/types';

  interface IProps {
    type?: string;
    name: string;
    value?: string;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    hint?: IFieldHint;
    autocomplete?: string;
    btnAction?: boolean;
    btnSize?: string;
    btnType?: string;
    btnText?: string;
    isLoading?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    type: 'text',
    isRequired: false,
    isDisabled: false,
    autocomplete: 'on',
    btnAction: false,
    btnSize: 'md',
    btnType: 'primary',
    btnText: '',
    isLoading: false,
  });

  const isEditing = ref(false);

  const emit = defineEmits(['blur', 'focus', 'input', 'update:value', 'submit', 'clickActionBtn']);

  const classes = computed(() => [
    'input-text',
    { 'has-error': props.hint?.variant === 'error' },
    { 'is-disabled': props.isDisabled },
    { 'is-hidden': props.type === 'hidden' },
  ]);

  const input = ref();
  const focus = () => {
    input.value?.focus();
  };

  const blur = () => {
    input.value?.blur();
  };

  defineExpose({ focus, blur });

  const onBlur = (e: any) => {
    isEditing.value = false;
    emit('blur', e.target.value);
  };

  const onFocus = (e: any) => {
    isEditing.value = true;
    emit('focus', e.target.value);
  };

  const onInput = (e: any) => {
    emit('input', e.target.value);
    emit('update:value', e.target.value);
  };

  const clickActionBtn = () => {
    emit('clickActionBtn', input.value?.value);
  };
</script>

<style src="~/assets/styles/components/form/input/text.scss" lang="scss" />
